const LocationReviewsData = [
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "David Ramos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Miss Maria Monroe",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "James Kennedy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michele C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Roger Green",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Jennifer Mason",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Frances L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Johnny Casey",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Victoria Newton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Walter Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Damon W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "John Thomas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gregg B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Donna Black",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jessica N.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Robert Allen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela O.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Jordan Bowman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lance N.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Elijah Jenkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ronnie L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Stacey Reynolds",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Kenneth Gonzalez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Rebekah Hess",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Miranda J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Dr. Jennifer Cantu",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rachael C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Lindsey Adams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Kelly Santos",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Bruce Warner",
        "service_type": "Garage Door Repair",
        "tech_name": "Destiny J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Justin Crawford",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Wayne G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Shannon James",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lauren W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Jacob Barnett",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cheryl L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Kelly Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Destiny R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Joseph Bennett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gary D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "John George",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Alexis Frederick",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anthony D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Chris Wolf",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Mary Wilson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sara S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Stacey Turner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rachel B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Mr. Steven Yang",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kelly T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Robert Zhang",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nathan F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Alexander D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Matthew Goodwin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Donna Perry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey O.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Denise Montoya",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Billy B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Sandra Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Juan W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Stephanie Campos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Hannah V.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Levi Allen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "John Todd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Linda Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Courtney Reese",
        "service_type": "Garage Door Opener",
        "tech_name": "Kathleen C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Dakota Mcgee MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stacey G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Alexander",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephen G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Kelly Allen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Karen T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Nicole Bryant",
        "service_type": "Garage Door Off Track",
        "tech_name": "Randy B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Kayla Gonzalez DDS",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patrick T.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Simpson",
        "service_type": "Garage Door Services",
        "tech_name": "Bryan J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Cassandra Bradford",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Micheal H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Tiffany Rogers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shannon M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Maria Mendoza",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Austin M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Susan Martin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephen G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Pamela Walton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Valerie G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Kenneth Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Luke B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Christine King",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tina S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Angela Watson",
        "service_type": "Garage Door Installation",
        "tech_name": "Justin L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Audrey Singleton",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Taylor Larson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Brandon Strickland",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Tammy Porter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathan A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Kelly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ryan C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Mark Weber",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Breanna B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Joyce Barnes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Heidi W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Lauren Knox",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Sara Crawford",
        "service_type": "Garage Door Repair",
        "tech_name": "Toni B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "James Salazar",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Keith R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Amy Shaffer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tammy P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Kathy Mccormick PhD",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "John Armstrong",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael Y.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Bowers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Jessica Ward",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rachel W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Douglas Ray",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kiara R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Willie Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Steven Hancock",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Laurie Gilmore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lisa N.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Scott Adams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Christopher Hamilton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott K.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Matthew Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Ann Ayala",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Zachary J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Michael Trevino",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joan R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Kelsey Huber",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alicia K.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "James Gallegos",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Brandon Hawkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Derrick J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Donna Bishop",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Samuel Morris",
        "service_type": "Garage Door Installation",
        "tech_name": "Alex K.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Maria Acosta",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kelly J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Misty Long",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shelly J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "William Rose",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Ashley Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Cameron O.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Kayla Bryant",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Caroline C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Tiffany Tucker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Edwin G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ronnie H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Stephanie Wagner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Catherine R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Melissa Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Joseph Nichols",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Laura H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Eric Martin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Tanya Griffin",
        "service_type": "Garage Door Off Track",
        "tech_name": "David G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Miller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carmen L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Mrs. Angela Boyle",
        "service_type": "Garage Door Maintenance",
        "tech_name": "April H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Jimmy J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Cynthia Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Richard Jackson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dawn V.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Kendra Foster",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tiffany M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "William Garrett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Janice W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Cameron Juarez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffery W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Jason Gonzalez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tony B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Jean Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Johnny W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "John Barrera",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Dylan Castillo",
        "service_type": "Garage Door Installation",
        "tech_name": "Margaret K.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "James Guerra",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jimmy G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Karen Hudson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Kerry Peterson",
        "service_type": "Garage Door Repair",
        "tech_name": "Christian D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Mallory Sanders",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shelley W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Luis Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Carolyn Little",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Eric Ford",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kenneth F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Yvette Rojas",
        "service_type": "Garage Door Services",
        "tech_name": "Ellen K.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Amy Richmond",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Linda Munoz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David T.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Adrian Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andrew A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "David Christensen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charlene G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Leon Contreras",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sheryl D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Holly Kaiser",
        "service_type": "Garage Door Services",
        "tech_name": "John B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Joseph Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amanda N.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "David Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Travis K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Sonya Butler",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Mr. Jeffrey Young",
        "service_type": "Garage Door Opener",
        "tech_name": "Alex W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Tracey Ryan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Francisco D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Jonathan Andrews",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daniel P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Danielle Diaz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jody W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jennifer King",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Stephen Warner",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Luis A.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Kayla Nielsen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Edward A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Johnathan Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Wendy B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Summer Patrick",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Stephanie Cook",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jason E.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Raymond Gould",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Victoria R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Melissa Hurley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Meagan T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Joshua Mcintosh",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Zachary S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Karen Hunt",
        "service_type": "Garage Door Opener",
        "tech_name": "Marissa P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Victoria Hicks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Johnny R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Michelle Ortega",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Brian Wu",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brittany L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Mark Fischer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Cody Perez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Zachary J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Erin Rowland",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ana C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Roach",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shirley S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Dennis Velasquez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristin H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Ann Davies",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brenda B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Lisa Jones",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kimberly M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Latoya Perry",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Jessica Stewart",
        "service_type": "Garage Door Opener",
        "tech_name": "Jacob B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Raymond",
        "service_type": "Garage Door Services",
        "tech_name": "Chelsea J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Rebecca Russo",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Janice G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Shawn Snyder",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mindy R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Teresa Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Crystal B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Debra Ortiz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jared J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Gina Diaz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Larry E.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Laura Hoover",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cory M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Derek Torres",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Barbara T.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Caitlin Lin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Michelle Perez",
        "service_type": "Garage Door Services",
        "tech_name": "Morgan L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Alyssa Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shelly H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Diane Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Harold G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Trevor Cook",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Daniel Kane",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Lori Cook",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Danielle Day",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Peter S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Samuel Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Todd L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Timothy Hampton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bruce G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Diane Allen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Barbara M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Patricia Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Benjamin G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Kimberly Cox",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melissa R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Pamela Luna",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Robert Manning",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Lauren Green",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Jacob Griffith DDS",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lori W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Stephen Fernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Daniel Odom",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robin S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Christian Moreno",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melissa C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Tonya Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Raymond Gomez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jesse H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "John Chambers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Linda H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "James Robbins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Frederick R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Jason Blanchard",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Eric Leonard",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Beth P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Roger Nguyen DDS",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alexis W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Christine Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathryn H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Matthew Prince",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Edward M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Andrew Hickman",
        "service_type": "Garage Door Installation",
        "tech_name": "Penny J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Danielle Velez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Veronica M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Robert Brooks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jerry H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Victoria Maxwell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samuel K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Deborah Harris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer A.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Melissa Jenkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kimberly W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Allison Manning",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ryan N.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Jasmine Day",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Charles L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Dawn Campbell",
        "service_type": "Garage Door Services",
        "tech_name": "Brian M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Amanda Hanson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Pamela Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Alan Willis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anna S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Mark Warner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Jennifer Hinton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Henry S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "James Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heather C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Kristopher Ramsey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephen B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Kelly Rivera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Adam Russell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Crystal Harvey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katherine W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Tyler Hoffman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Kevin Trevino",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Emily C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Tyler Peterson II",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristen S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Mallory Hernandez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Travis C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Roger Warren",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Diana E.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jesse Flores",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Sandra Poole",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Robin Armstrong",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christina B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Joseph Johns",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Corey W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Samantha Vasquez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jacob B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Sylvia Marquez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Zachary W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Shelley Lin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Brian Moore",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sharon M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "John Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Zachary Hinton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stacy T.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Joel Padilla",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Darrell W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Crystal Christian",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Annette Parks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Darryl W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Miranda Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jonathan W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Kristi Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Virginia Simon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Jason Hernandez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Barbara R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Roger Benton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nathan G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Andrea Blair",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tristan H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Timothy Cruz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Vicki J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Barry Freeman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shawn A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Jose Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Dominique B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Katrina Wilson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Fred P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Paul Wilson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Andrew Larsen",
        "service_type": "Garage Door Repair",
        "tech_name": "Heidi J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Bobby Friedman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tyler M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Oscar Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Shelby J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Murray",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Joseph Lawrence",
        "service_type": "Garage Door Repair",
        "tech_name": "Jay O.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Cristina Spencer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Cynthia Collins",
        "service_type": "Garage Door Services",
        "tech_name": "Thomas T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Desiree Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Juan L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Larry Hurley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Louis W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "David Page",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Jacob Schroeder",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amber E.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Manuel Khan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephen W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Robert Ramos",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Judy S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sara Salazar",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katherine W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Brooke Brooks",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "David Fisher",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Karen Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Tracy Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Zachary S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Melissa Morris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Scott S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Deborah Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Patricia Carr MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexis M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Donna Howard",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Levi J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Thomas Tucker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Angelica Combs",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Edward Gonzales",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Henry P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Brandon Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kenneth Y.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Meredith Ramirez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Karen Hartman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicole O.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Veronica Barnes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Mrs. Patricia Diaz PhD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kayla B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Nancy Hall",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samantha P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Brandi Vega",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Nicholas Jennings",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Abigail Fields",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicholas H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Jerry Mcdowell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin K.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Kim Wise DVM",
        "service_type": "Garage Door Repair",
        "tech_name": "Craig C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Sarah Campbell MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brian B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Jennifer Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Eric A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Rachel Ibarra",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "John Harris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Allison Wiley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chris C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Charles Avery",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Hannah Hodge",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jean A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Brian Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Laura M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Linda Hunter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Darren C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Christine Jackson",
        "service_type": "Garage Door Services",
        "tech_name": "Emily G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Samantha Cunningham",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brianna H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Sydney Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sandra D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Donald Carter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Barbara P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Angela Zhang",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gregory H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Duane Herrera",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joel V.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Patrick Pope",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacqueline T.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Isabella Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Denise L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Gary Villanueva",
        "service_type": "Garage Door Services",
        "tech_name": "Sara R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Kevin Benson",
        "service_type": "Garage Door Services",
        "tech_name": "Cynthia C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Brenda Cross",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Tonya Howard",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Susan B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "George Taylor",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kathleen F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Michelle Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kenneth J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Mr. Drew Glass",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Natalie Green",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Adam Patterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gina M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Marvin Berry",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "William M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Miranda",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Belinda S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Todd Salazar",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tara M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Michael Robinson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sharon R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Jon Mckee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Valerie Green",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Bradley Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Curtis Villarreal",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Pedro Lyons",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Bryan H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Juan Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kyle R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Melissa Brewer MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Benjamin Y.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Matthew Hoffman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Justin D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Decker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Raymond M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Molly Houston",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Ms. Misty Edwards",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Chelsea L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Alyssa Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Timothy Moore Jr.",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christina R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Perkins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Laura Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samuel N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Tonya Torres",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Benjamin W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Benjamin Blankenship",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Erica Miranda",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Eric Mclaughlin",
        "service_type": "Garage Door Opener",
        "tech_name": "James A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Aaron Harrington",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Martin C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Corey Daniels",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Donald Cruz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steve T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Michael Sawyer DVM",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Paul Atkinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Connor A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Michael Hill",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melvin B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Courtney Bennett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Micheal K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Karen Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Lydia Blackburn",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Kathleen Spencer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Laura G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Linda Ochoa",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Susan P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Jonathan Campbell DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ellen H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Victor Robles",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lynn F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Phyllis Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Doris Sanchez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lindsey N.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Kevin Horn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Jennifer Macdonald",
        "service_type": "Garage Door Installation",
        "tech_name": "Bradley S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Courtney Campbell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Austin P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Tiffany Doyle",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Thomas R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Stephen Cooper",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lauren W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Mark Bartlett",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Suzanne N.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Erin Becker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Tina Roberts",
        "service_type": "Garage Door Repair",
        "tech_name": "Sharon S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Mclaughlin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brenda W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Ryan Anderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sheila V.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Erica Pacheco",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Rachel Schwartz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Courtney M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Gabriel Good",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nathaniel P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Solis",
        "service_type": "Garage Door Installation",
        "tech_name": "Anne F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Kelly Leblanc",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Scott M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Kristine Dean",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Leah M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Linda Dominguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Linda P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Lisa Morrison",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Jessica Burton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "David Bishop",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Craig D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Michael Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Julia Ferguson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Linda P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Paul Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Diana Phillips",
        "service_type": "Garage Door Off Track",
        "tech_name": "Susan S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Christopher Hayes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rebecca G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Joseph Bell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Donald W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "John Bush Jr.",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Michael Rodriguez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Margaret P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Amy Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Jonathan B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Caitlin Torres",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tracey T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Paul Le",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Rose Harvey",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Wayne G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Michele Sullivan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adam W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Joanna Lopez",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Kimberly Snyder",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "David Adams",
        "service_type": "Garage Door Services",
        "tech_name": "Philip V.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Ryan Lopez",
        "service_type": "Garage Door Services",
        "tech_name": "Sean M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Ashley Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Misty J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Cory Gonzalez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jonathan H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Nicholas Malone",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Amanda Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Teresa B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Kelly Cook",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristine S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Christina Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Peter Ruiz",
        "service_type": "Garage Door Repair",
        "tech_name": "Tina G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Derrick Edwards",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nathan H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Stacie Sanchez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Bryan Rose",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashley B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Mr. Michael Stevens",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julia W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Michael Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kaitlyn C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Tara Barrett",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Isaiah C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Valerie Hunt",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gabriel S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Juan Howard",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Gail Sutton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Scott Olsen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sheila S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Karen Tucker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephen F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Lauren Cameron",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Mr. Todd Clements",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dylan M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Nancy Chung",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph F.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Arthur Parks",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Megan B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Cathy White",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anthony C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Alexis Stanton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Gregory Lane",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Christopher Jensen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anna T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Timothy Valentine",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Donald O.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Melissa Tran",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Noah Charles",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Leslie S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Stephanie Henry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacqueline B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Christopher Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Jesse Ritter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bethany H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Brian Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Omar Sanchez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kyle G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Susan Gentry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Foley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Sarah Swanson",
        "service_type": "Garage Door Services",
        "tech_name": "Angela G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Antonio Gutierrez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Catherine C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Chelsea Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jane W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Shawna Evans",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Diana C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Chelsea Perry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Samantha Vaughan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marvin P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Daniel Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anna B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Michael Thornton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Melissa Castillo",
        "service_type": "Residential Garage Door Services",
        "tech_name": "April S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Thompson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Gary Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Melissa House",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca A.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Gerald Montgomery",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Fred S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "David Rios",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rachel M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Katrina George",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melanie J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Mary Brennan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Theresa M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Tonya Mccullough",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mikayla B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Barbara Mora",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Ryan Ramirez MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kyle S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jimmy Hurley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrew B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Charles Curtis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tiffany M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Stacy Brewer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Sabrina Yoder",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Mark Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gina R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Nicole Willis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Lindsay Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nathaniel T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Nicholas Bailey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samantha A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Jenna Baker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eileen F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Andrew Jensen Jr.",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brianna A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Justin Ortiz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Christopher Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "Raven C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Lori Schneider",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eric F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "William Holmes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Latoya M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Richard White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Edward R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Pamela Smith MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sean M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Bernard Murphy",
        "service_type": "Garage Door Installation",
        "tech_name": "Nicole F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "John Jordan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Miguel Meyer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Teresa B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "David Dixon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Anita Wall",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel Z.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Miguel Ellison",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Kimberly Miranda",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Abigail W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Kimberly Wise",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patrick M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Joan Macdonald",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jose L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Sergio Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jacob H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Michael Murphy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Albert J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Maurice Acevedo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "John Wood",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sherry G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Wayne Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Philip Moore",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alison W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Raven Hale",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Stephen Flores",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephen J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Michael Russo",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Sara Sims",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Eric W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Johnny Mcdaniel",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Audrey D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Sharon Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Morgan A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Tina Allen MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Andrew Evans",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Michael Meyer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gabrielle S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Nicholas Lee",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephen M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Timothy Kidd",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julia S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Kristen Wright",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrea R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Paul Rivas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Natalie Torres",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheila S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Anthony Hanson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Andrea Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Melvin Haynes",
        "service_type": "Garage Door Services",
        "tech_name": "Linda R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Mary Diaz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Seth C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Scott Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Jessica Green",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patricia Z.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Rebecca Boyd",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bradley B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Valerie Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Morgan R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Donna Coleman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gary S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Joseph Valdez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shannon S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Juan Ray",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jamie Walters",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Wanda E.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Timothy Vaughn",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Miranda H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Tonya Williamson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dennis B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Brandon Woods",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Megan R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Carolyn Fowler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jerry H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Antonio Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Troy F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Karen Clark",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sandra H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Amanda Hall",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sabrina W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Michelle Sims",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeffery M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Kimberly Taylor",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Joshua Clark",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Larry D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Nicole Miller PhD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Katrina Best",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anna H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Heather Bailey",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Steven S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Patricia Nunez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kim B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Stephanie Morales",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sara W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "David Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jerry B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Victor Tucker",
        "service_type": "Garage Door Opener",
        "tech_name": "Christina D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Stephen Phillips",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicholas H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Rebecca Burnett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Oscar W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Erica Hart",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Janet F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Matthew Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dave L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Michael Mcclain",
        "service_type": "Garage Door Repair",
        "tech_name": "Douglas T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Miss Rhonda Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Kendra Shaw",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Tanner Cox",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James T.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Angel Rivas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Holly R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Jade Berger",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Crystal Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Thomas J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Johnny Lewis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julie H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Amanda Barron",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Teresa Gordon",
        "service_type": "Garage Door Services",
        "tech_name": "Johnny M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Ashley Matthews",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katherine R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Jeff Patel",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Robert Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charles K.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "George Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Mr. Matthew Rojas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shawn P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Lisa Chang",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cassandra M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Traci Ballard",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Cindy Lynch",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Gabriela Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Terri T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Richard Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Mitchell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Norma H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "April Lucas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Emily R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Jeremy Rojas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Jacob Cruz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Phillip Christensen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Gregory Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Maureen C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Emily Rivas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melissa G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Stephen Cain",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel N.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Fernando Buchanan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Samantha Allen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "John Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ronald L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Robyn Mccoy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jose S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Marissa French",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Diane Guerrero",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Danielle Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Bianca Parrish",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christina G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Diane Walker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Holly L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Daniel Butler",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Terri Norman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Teresa W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Amy Leon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Charles F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Kimberly Peters",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Nancy Hayes",
        "service_type": "Garage Door Opener",
        "tech_name": "Caleb R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Teresa Little",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Ashley Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Nicole Mayer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jordan W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Taylor Graves",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gail V.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Jessica Michael",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert A.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Curtis Washington",
        "service_type": "Garage Door Insulation",
        "tech_name": "Connor W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Lauren Oliver",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Eric Soto",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathleen W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Mr. Jason Henry MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jose C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Tony Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Thomas P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Ronald Mullins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sabrina C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Alejandro Vazquez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brent M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Christopher Peterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amber F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Thomas Kemp",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Justin Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patricia S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Peter Ramsey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Wyatt M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Laura Marquez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Cassidy Simpson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alexandria L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Jesse Daugherty",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Matthew Hill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patricia H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Anthony Wang",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelly R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Brian Donovan PhD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Samantha Bates",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cindy D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Joshua Chandler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bradley J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Tammy Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tiffany C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Gary Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Candice R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Robert Obrien",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Thomas Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Lisa Powell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christine J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Joshua Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Christina Nelson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesse C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Jason Vincent",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Carolyn J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Miranda Gonzalez",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Alexandra Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Lauren C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Rachel Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Angela Torres",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ellen B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Sydney Kramer MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Hayley S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Mr. Mark Baldwin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Julie H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Keith Snow",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alexis M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Kimberly Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Patrick H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Jennifer Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jane B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Vanessa Carr",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Derek Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Scott Hayes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Timothy Clark",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alyssa G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Amanda Ellis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Grant Garner",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Laura Norton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Paul F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Eric Snow",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "David Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Michael George",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jonathan H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Monica Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Cynthia Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kayla C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Stephanie Pierce",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shane R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Jeffrey White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Randy W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Caleb Bates",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dana M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Amy Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Gaines",
        "service_type": "Garage Door Opener",
        "tech_name": "William B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Holly Mora",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Whitney O.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Sean Paul",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeanette L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Denise Berry",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason V.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Lindsay Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Caitlin Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Paige S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Kara Carlson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sherri A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Elaine Richardson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Chelsea D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "John Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Robin Ortiz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Michele Meyer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Michelle Baker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Debra Bartlett",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Derek Peterson",
        "service_type": "Garage Door Installation",
        "tech_name": "Nathan B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Rodney Neal",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andre H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Perkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Eric S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Emily Banks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Elizabeth M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Jade Cervantes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heather M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Allison Hernandez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Jason Chavez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Kelsey Harris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ivan R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Whitney Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jamie S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Eric Foster",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tony R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Amy Duncan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tyler P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Melanie Finley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alexandra D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Laura Petersen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Renee G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Seth Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "William Wiggins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Suzanne R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Brenda Nolan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melinda B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Jesus Nielsen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Chad J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Patricia Summers",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rachel P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "John Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alex C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "James Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Holly M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Samantha Hart",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Vincent Robles",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Perry P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Curry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Heather J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Michael Barnes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Mrs. Kristine Kerr",
        "service_type": "Garage Door Services",
        "tech_name": "Maria J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Karen Hood",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sheila G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Joseph Sawyer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Latasha O.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Jordan Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katelyn W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Marilyn Green",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Julia James",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jean P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Rachel Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Colleen S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Daniel Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Angela Kidd",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andres M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gonzales",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dana R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Mrs. Jessica Wheeler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Patrick Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephen J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "David Hodges",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Todd Buck",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Charles Fuentes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Jesse Sullivan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Derrick H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Dr. Michael Mclaughlin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Troy Lawrence",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Sandra Jones",
        "service_type": "Garage Door Services",
        "tech_name": "Louis M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Heather Mccall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Laura W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jordan Pittman",
        "service_type": "Garage Door Installation",
        "tech_name": "Jessica W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Cindy Anderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephen B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Joseph Cruz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shelley J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Danielle Huang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Kathy Sweeney",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shawn C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Jessica Park",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amy R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Kathleen Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rachael W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Travis Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andres H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Adams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Victor H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Glenn Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Michael Burns",
        "service_type": "Garage Door Services",
        "tech_name": "Micheal P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Paul Prince",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Justin D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Samantha Wood",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kerri H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Anthony Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Wanda Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brenda D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Laura Andrews",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Roger D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Suzanne Leon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michele H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Mitchell Chang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Daniel Blackwell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Roy Herrera",
        "service_type": "Garage Door Insulation",
        "tech_name": "John M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "David Duran",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "David Collins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Mary Simpson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly Y.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Jason Patterson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Todd S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Crystal Carr",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gregory R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Kelli West",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Jennifer Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Johnathan T.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Stephanie Chandler",
        "service_type": "Garage Door Services",
        "tech_name": "Danny V.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Cory Thompson DVM",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Juan R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Rebecca Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jerry B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Nathan Hull",
        "service_type": "Garage Door Insulation",
        "tech_name": "David C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Logan Vaughn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shelly L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Joseph Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Kayla Jenkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sarah G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Joseph Nelson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Jeremy Calderon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Amy Luna",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Laura Wiley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Allen G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "James Rocha",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Spencer P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Mrs. Carolyn Mendez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Adam S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Kathleen Garza",
        "service_type": "Garage Door Opener",
        "tech_name": "Wesley G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Lauren Burch",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachael G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Loretta Wright",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vanessa G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Amy Good",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Megan Clark",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Danny L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Mr. Eric Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Hailey White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jason C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Sergio Olsen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julian H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Matthew Ortiz",
        "service_type": "Garage Door Installation",
        "tech_name": "Vanessa B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Tiffany Peters",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Timothy Friedman",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Teresa Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shawn D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Carlos Valdez",
        "service_type": "Garage Door Opener",
        "tech_name": "Marilyn M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Spencer Bradley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Douglas L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Jill Jimenez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Donald Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Fernando H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Jacob Benton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dylan B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Lucas Cervantes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ryan H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Ethan Cole",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Larry M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Tina Murray",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katrina M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Gina Cox",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Adam Campbell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Martin H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Kathryn Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bradley M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Ms. Natalie Castillo",
        "service_type": "Garage Door Repair",
        "tech_name": "Brenda C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Robert Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandon J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Cindy Yates",
        "service_type": "Garage Door Opener",
        "tech_name": "Peter H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "James Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Candice Stanley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heather M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Ashley Ruiz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Edward M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Christina Mccoy",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jay M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Bobby Padilla",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Michael Anthony",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "James Murphy",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sarah R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Briana Cochran",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Sarah Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Garrett Nelson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alicia B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Michelle Hunter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Keith K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Harry Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sydney M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Craig Holt",
        "service_type": "Garage Door Installation",
        "tech_name": "Daniel A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "John Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Marissa B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Becky Freeman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Edward S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Taylor Campbell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Joyce Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Zachary H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Kathryn Foster",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Danielle R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Autumn Bell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Caroline S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Ethan White",
        "service_type": "Garage Door Repair",
        "tech_name": "Walter B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Renee Murphy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shawn W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Jennifer Short",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Kathy Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Shelia G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Mitchell Larsen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lorraine M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Carlos Hill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rachel K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Vanessa Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Eric Young",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Suzanne Hamilton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Albert B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Thomas Grant",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kenneth R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Beth Coleman",
        "service_type": "Garage Door Services",
        "tech_name": "Catherine J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Amanda Scott",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristen S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Jeremy Ramirez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Adam Peters",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kenneth H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Angela Horne",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Jamie Espinoza",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Theresa F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Jody Rhodes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebecca R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Tammy Perez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Michael Cole",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Linda T.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Robin Novak",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Pamela A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "David Kline",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jamie S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Tammy Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard O.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Randy Crawford",
        "service_type": "Garage Door Off Track",
        "tech_name": "Courtney R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Bradley Warner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Johnny J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Leroy Pena",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Peter A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Miss Lindsay Grimes",
        "service_type": "Garage Door Services",
        "tech_name": "Jason S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Ms. Elaine Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Johnathan S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Lisa Edwards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Adams",
        "service_type": "Garage Door Installation",
        "tech_name": "Connor O.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Matthew Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Allison H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Joseph Kelly",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Natalie Thomas",
        "service_type": "Garage Door Insulation",
        "tech_name": "William E.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Michael Hendrix",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carrie S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Maureen Ward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Scott Morris",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Denise Watkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Heather G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Jasmine Reeves",
        "service_type": "Garage Door Services",
        "tech_name": "Brittany S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Sara Martinez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Trevino",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Olivia D.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Tanya Knight",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ian R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Marissa Phillips",
        "service_type": "Garage Door Installation",
        "tech_name": "Abigail D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Wesley Webb",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jonathon O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "William Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Yolanda L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Jessica Walton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Crystal N.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Knight",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anne J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Ian Mcgee IV",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kevin P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Wendy Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Zachary Palmer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alison S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "David Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Linda H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Dominic Donovan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Benjamin Peterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Stephanie Wise",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dennis M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Jordan Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Chad C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Nicholas Vargas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cynthia M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Jessica Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Billy M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "John Mcintyre",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Sean Kim",
        "service_type": "Garage Door Services",
        "tech_name": "Chad B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Sophia Ruiz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Tiffany Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie K.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "William Novak",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer O.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Eric Rodriguez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samantha O.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Lauren Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erik M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "William Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tim W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Robert Reynolds",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Denise Duran",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "James Joyce",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laura B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Olivia Doyle",
        "service_type": "Garage Door Services",
        "tech_name": "Erik W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Maria Scott",
        "service_type": "Garage Door Opener",
        "tech_name": "Johnathan W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Brandon Riley",
        "service_type": "Garage Door Services",
        "tech_name": "Marcus D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Randy Torres",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Susan W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Kenneth Young",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Donna A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Monica Page",
        "service_type": "Garage Door Repair",
        "tech_name": "Mary H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Byrd",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Jason Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Erin S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Ryan Woodward",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Eric Thornton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Donna W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jesus Rogers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bryan D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Joe Hamilton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Julia Lee",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bradley N.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Rita Bowman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Todd Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan Z.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Alyssa Yates",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Deborah Hubbard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Maria Andrade",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathaniel M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Victoria Garner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Thomas Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tyler W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Margaret Washington",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samuel K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Scott Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christina D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Alison Patterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michele R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Robert Irwin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeremy J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Raymond Delacruz",
        "service_type": "Garage Door Opener",
        "tech_name": "Brooke B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Jean Davis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "John Rose",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Timothy Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tina H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Timothy Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Walter M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Brandon Gomez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher K.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Lauren Morgan DVM",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Teresa W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Marvin Stevens",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samuel Y.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Roy York",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kyle L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Darryl Wise",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brent F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "John Clark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rebecca B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Brian Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tabitha J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "James Contreras",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dawn C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Jonathan Riley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lori W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Megan Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Michael Aguilar",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gail R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Timothy Fitzgerald",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Amber Ray",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Latoya J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Tracy Prince",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Suzanne P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Michael Pena",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michele B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Renee Armstrong",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Russell W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "William Cain",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kathleen A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Michael Peterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Jose Bauer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Penny M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Kimberly Snyder",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Andrea Lewis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Paula Howe",
        "service_type": "Garage Door Services",
        "tech_name": "Anna J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Shawn Vance",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lauren E.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Henry Black",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kyle L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Linda Boyle",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Hector Rose",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alicia A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Joshua Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Kimberly Martinez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jose S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Michael Morton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Meagan R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Kimberly Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Ryan Mosley DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Jenkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Martin Rhodes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Donna S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "John Ferguson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brenda H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Diane King",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Boone",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cody P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Eduardo Hanson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lauren C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Bailey Kennedy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephen L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Christy Romero",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony F.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Henry Steele",
        "service_type": "Garage Door Services",
        "tech_name": "Danny W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Susan Nelson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Patricia Chapman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heather C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Melissa King",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica N.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Scott Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Diane J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Matthew Ross",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jamie B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Maria Harper",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sarah J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Andre Hernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gary R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Tara Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anna M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Renee Martin",
        "service_type": "Garage Door Services",
        "tech_name": "Bernard L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Kenneth Bennett",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christine H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Eddie Grimes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lauren J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Daniel Conley",
        "service_type": "Garage Door Repair",
        "tech_name": "Dave W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Matthew Simmons",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert N.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Michelle Pearson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Timothy Lane",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Virginia K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Tanya Cox",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Charlotte Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sabrina E.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Kelly",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Kevin Hodges",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Jeffery Frazier",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexandra B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Edward Woods",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Luis B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Emily White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tiffany B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "William Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrea H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Paul Smith MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Ruth Singh",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Julie H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Heather Huynh",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paul H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Donna Fuentes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cindy P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Kelsey Mitchell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Alison Barrett",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Shirley Watson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marissa R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Leslie Vaughn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jesse Y.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Tami Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "David M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Caroline H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Jesse Blake",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kendra G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Gerald Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Phillip R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Robert Gonzalez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Andrew Dawson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Diane Boyer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sandra J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Kimberly Juarez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eric A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Luke Rose",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Zachary W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Robert Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Diamond Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donald A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Karen Farrell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Edward M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Bryan Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tiffany J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Bryan Ramirez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Roger P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Jennifer Francis",
        "service_type": "Garage Door Services",
        "tech_name": "Jamie D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Brianna Hunter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Janet S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Justin Delgado",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Patricia Atkinson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandon B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Sara Burns",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elaine G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Daniel Peterson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kristina J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Acevedo",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Marc Harris",
        "service_type": "Garage Door Installation",
        "tech_name": "Katelyn R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Timothy Lowery",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patrick M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Joshua Allen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Jennifer Green",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Pamela C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Karen Knapp",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andres B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Dennis Scott",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Richard R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Joel Carter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Emily S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Kimberly Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Tyler Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Deborah Hayes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brent H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Heather Davidson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Trevor C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Ronald Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle V.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Kayla Ryan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Aaron S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Charles Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cynthia G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Stephanie Stevens",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Anne Yang",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Sarah Baker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Derek Ross",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin K.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Thomas Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Edward Bryant",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Richard Hart",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donald B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Steven Howell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Jamie Hammond",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Megan D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Michelle Hernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Karen P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Crystal Larson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jamie M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Stephen Crawford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Paul P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Craig Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Renee Brewer",
        "service_type": "Garage Door Services",
        "tech_name": "Michael S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Jessica Klein",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Bruce K.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Scott Sandoval",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ian E.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Ruth Sanders",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeremiah M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Christian Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jenna H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Matthew Bautista",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erik J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Linda Anthony",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Lacey Ramirez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joel B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Karen Drake",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Carrie Atkinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Catherine H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Joseph Cross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sharon W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Tiffany Key",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michelle A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "James Jackson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jose L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Ronald Martinez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eileen H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Thomas Bishop",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alan A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Kendra Edwards",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Michele Vincent",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gina W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Joshua Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelly M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Joshua Lucas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Mrs. Lorraine Bowers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Joyce Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christina E.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Paul Mclaughlin",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Melanie Washington",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelsey A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Jeremy Jefferson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andres M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Kelly Sharp",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brent B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Daniel Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Emily Summers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Krystal B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Angela Jackson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Bradley Howe",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gabrielle E.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Shaun Hurst",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Heidi Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ronnie B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Colleen Grant",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Dr. Michael Bray",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Steve W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "David Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Isabel H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Sydney Gill",
        "service_type": "Garage Door Services",
        "tech_name": "Charles M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Glenn Moyer",
        "service_type": "Garage Door Opener",
        "tech_name": "Annette T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Martinez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ryan S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Emily Wang",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Craig Nelson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristen B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Kevin Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brittney M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Greg James",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Danny C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Brandy Snow",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jacqueline H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Alexis Boyle",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kayla C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Chad Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amber T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Samantha Rodriguez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stephen R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "David Flores",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erica G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Mackenzie Rios",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Calvin P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Katherine Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffery R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Horn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "James Davis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Monica H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Lisa Bray",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Amanda Phillips",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brian T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Kyle Brennan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicole W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Pedro Pierce",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christine B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Micheal Dixon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael T.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Vanessa Owens",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Beverly J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Mr. Dennis Douglas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Janet W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Laurie Love",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jonathan Green",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Dana Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Tiffany Gallagher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lauren E.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "David Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cindy W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Nicole Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Garrett Owens",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jose B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Michael Mills",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heather G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Sylvia Berry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ariel M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Allison Singleton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Benjamin M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Ramirez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Julie M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Bryan Adams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Andrea Peters",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Jessica Hill",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christine O.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "James Watson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Michael Whitehead",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Jessica Richards",
        "service_type": "Garage Door Opener",
        "tech_name": "Billy B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Dorothy Stevens",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michelle S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Rachel Murray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lauren H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "John Hanson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ana G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Tammy Reed",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sheryl N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Mr. Justin Waters Jr.",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michele H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Kaylee Chapman",
        "service_type": "Garage Door Installation",
        "tech_name": "Barbara S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Donna F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Kiara Bird",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Richard S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Edwin Morgan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Suzanne S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Austin Abbott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Stacey Li",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Rachel Roberts",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Andrew Crane",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Allison M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Amy Santos",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ricky R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Sean Robertson",
        "service_type": "Garage Door Services",
        "tech_name": "Donna R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Louis W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Bridget Fischer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Steven M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Benjamin Nguyen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jose W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Tara Roth",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Ashley Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Gregory Ramos",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Theodore D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Michelle Hart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Alexandra Thompson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John E.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Alex Torres",
        "service_type": "Garage Door Off Track",
        "tech_name": "Valerie W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Kathy Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Roberto P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Mr. Kevin Elliott",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Wright",
        "service_type": "Garage Door Repair",
        "tech_name": "Jamie G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Colleen Roy",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Martha Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Misty D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Matthew Maldonado",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Donna J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Sarah Morrow",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Henry L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Christine Hanna",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Lisa Wright",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelly K.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Cory Kelly",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Rebecca Costa",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ryan D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Raymond Lowe",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gina B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Victor Todd",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bradley F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Cortez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Rachel Ballard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Debra D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "James Briggs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Collin Suarez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Janice L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Ronald Thomas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Sarah Henry",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Mary Figueroa",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexander M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Phillip Andrews",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Nicholas Mckinney",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brenda R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Allison Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cynthia R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Alan Trevino",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Mays",
        "service_type": "Garage Door Repair",
        "tech_name": "April P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Cassandra Rogers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gina B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Julia Kennedy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Gregory English",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebekah V.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Abigail Cuevas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lee G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "David Joseph",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rickey R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Dennis Goodman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Dylan Parks",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sean G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "William Mueller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jonathon B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Sherry Ross",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Teresa H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Jessica Patton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kenneth G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Linda Turner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Julie Goodwin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Stephanie Becker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle E.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Jorge Murillo",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Scott C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Lauren Mcdonald",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Adam G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Brooke Klein",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Brian Coleman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Zachary J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Mark Cox",
        "service_type": "Garage Door Repair",
        "tech_name": "Tracey F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Eric Holloway",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Denise L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Zachary Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Dylan A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Melissa Turner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Jennifer Love",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carly J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Stacy Wallace",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Ethan Serrano MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Tonya Walters",
        "service_type": "Garage Door Services",
        "tech_name": "Cody F.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Brian Bailey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremy M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Amy Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marilyn D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Lisa Eaton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Barbara J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Linda Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffrey J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Kayla Perez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jason L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Brian Becker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Briana P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Robert Beck",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Peter J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Russell Gibbs",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Klein",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas D.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Regina Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Audrey B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Kelly Maynard",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Sarah Lawson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Savannah R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Joseph Austin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Blake B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Sheila King",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brooke W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Lisa Oliver",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Shannon Turner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexis J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Coleman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jamie S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Kimberly Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Robert Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrea T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Christina Moore",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Stephanie Byrd",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Billy H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Nicole Holder",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Mr. Wesley Hall PhD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica N.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Douglas Gregory",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lori L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Vicki Bishop",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carly B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Anna Goodwin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "David Ramirez",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy O.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Adam Richardson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Terri Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lori H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Deborah Moody",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Curtis R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Shawn Ramos",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Craig P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Jennifer Avila",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jose H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Catherine Hunter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Zachary Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "George Sanchez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patrick D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Kristen Russo",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Diane G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "John Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bradley P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Robert Clark",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Melody Washington",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Courtney J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Devin Riley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Emily F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Harry Gonzales",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Diana D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "James Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Michael Murphy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Logan A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Robin Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Steven J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Robert Phillips",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Sean Evans",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cory R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "John Carroll",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Sarah Adams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Edwin Diaz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "William Navarro",
        "service_type": "Garage Door Opener",
        "tech_name": "Dakota G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Christine Ingram",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Jessica Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Tina W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Andrea Cole",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Wong",
        "service_type": "Garage Door Repair",
        "tech_name": "Denise L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Miss Bethany Matthews",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Edward D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Joseph Lewis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Erik Hull",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Brittany Lindsey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Maxwell C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Mitchell Graham",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Jerry Norris Jr.",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Thomas M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Keith Hood",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cynthia J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Megan Burns",
        "service_type": "Garage Door Repair",
        "tech_name": "David T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Hector Campbell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mitchell O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Kevin Hood",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rick S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Tammy Murphy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Paula Lucas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Erica Palmer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Steven C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Michele Powell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Molly V.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Casey Lewis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Dana Bishop",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Denise M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Frederick Cox",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stacey M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Travis West",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michele D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Lester",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Barbara H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Jennifer Gomez",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Kim Farley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brenda V.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Melissa Landry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jose R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "David Allen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "David Sharp",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stacy B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Benjamin Gomez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Caleb S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Kayla Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Claudia Perkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brenda H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Rachel Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Michael Sullivan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Melissa Baker",
        "service_type": "Garage Door Repair",
        "tech_name": "Pamela R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Adams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Raymond W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Daniel Stephens",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sean D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Bonnie Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Jamie Rangel",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Joel Morris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Amy Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Frances H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Julie Mccullough",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Lewis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Megan C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Seth Martinez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Brian Gonzalez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Thomas Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffery M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Michael Reid",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Emily R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Brian Norton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shannon S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "David Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Cody F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Laura Romero",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Donna K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Robert Morris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Faith Gardner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alicia K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Christina Christensen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "William M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Brittney Lopez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lori G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Brent Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christine P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Michael Booth",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christine B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Chad Fisher",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Levi L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Koch",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Travis F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "John Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Linda B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Connie Olson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carl M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Shannon Baker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathon B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Randall Mcintosh",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Morgan B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Ariana Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "William W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Jonathan Norman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelly S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Corey Ramos",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Victoria P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "James Kidd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Alec Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Jasmine C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Robert Robinson",
        "service_type": "Garage Door Services",
        "tech_name": "Kristina G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Dana Hall",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Sabrina King",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Danny Avery",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Barry Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Timothy S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "William Reid",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Dylan W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Michael Avery",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Natalie R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Randy Miles",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Raymond D.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Karen Rivera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Kyle Decker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Tran",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Michael Le",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Adam Lane",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mary C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Gregory Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Hailey B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Melissa Munoz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Destiny R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Dennis Ali",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cheryl F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Brittany Thomas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jacqueline E.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Ann Crane",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Marvin C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Felicia Price",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sonya C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Eric Martin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Mindy Stanley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa I.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Luke Lopez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristen W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Shawn Pham",
        "service_type": "Garage Door Installation",
        "tech_name": "Shirley B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Lori Thomas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexandria M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "John Rice",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Derrick B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Ruben Jimenez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Howard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tara G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Thompson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Benjamin J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Bethany Hartman",
        "service_type": "Garage Door Opener",
        "tech_name": "Clayton M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Amanda Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amy W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "James Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "William R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Stanley Frank",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Shannon Mercer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Jamie Cooley",
        "service_type": "Garage Door Services",
        "tech_name": "Nicholas A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Ian Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cody F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "David Scott",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy O.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Jackie Leach",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Tonya Fisher",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Darrell Andrews",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicole N.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Brenda Wade",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Theresa Douglas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gabriel G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Brooke Bean",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Tyler Fischer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Richard Castro",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Albert J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Isaiah Lewis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kenneth A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Julie Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Dawn Osborne",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wanda S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Chelsea Krause",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony N.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Katherine Bartlett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Garcia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Timothy Hudson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Jason Cantu",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Deanna B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Ball",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shawn T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Jane Mosley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Paul E.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Richard Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Stephen Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelsey P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Eric Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Jackson Pearson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cassandra J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "John Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tina W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "David Ford",
        "service_type": "Garage Door Insulation",
        "tech_name": "Timothy W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Ashley Peterson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Michelle Mcpherson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christina M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Ellen Mccoy",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Sara Cox DDS",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Paula K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Luis Hart",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melinda A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Christian Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Frank M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Michelle Moyer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nancy W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Cathy Griffin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacey G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Richard Martinez",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Lisa Reeves",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Regina Short",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeffrey B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Robin Best",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Madison T.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Courtney Chavez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Sabrina Archer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Duane W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Mary Cabrera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Natasha W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "David Drake",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Benjamin N.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Charles Chan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jackson S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Richard Bender",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Katherine A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Paula Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Renee M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Parker",
        "service_type": "Garage Door Opener",
        "tech_name": "Dennis T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Heather Whitehead",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anne P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Maureen Lee",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Erin T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Nicholas Burke",
        "service_type": "Garage Door Services",
        "tech_name": "Luis M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Donald Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mallory C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Anita Butler",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Isaiah Sampson",
        "service_type": "Garage Door Repair",
        "tech_name": "Rodney A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Connor Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jerry O.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Tiffany Mccoy",
        "service_type": "Garage Door Opener",
        "tech_name": "Lindsay B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Marvin Petersen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erika A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Chelsea Cook",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alison G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Fisher",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Natasha S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Courtney Cunningham",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Andrea Schmidt",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cameron R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Benjamin Perkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Charles L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Joseph Flores",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tabitha R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Brandon Hampton",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Casey Ward",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer Q.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Michael Nunez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Ryan Simmons",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Jonathan Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "George Glenn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ricky C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Shannon Jensen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Stephanie Henderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erin G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Aaron Manning",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Scott Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "John Dixon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christine C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Cynthia Munoz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lauren R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Kevin Douglas",
        "service_type": "Garage Door Repair",
        "tech_name": "Adrienne V.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Michael Bradshaw",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelsey H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Jose Parks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christina G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Peter Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kenneth R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Allison Rose",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Micheal H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Julie Henry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Raymond A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Kimberly Gray",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Emily R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Sarah Shelton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ronald A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jasmine Silva",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Hannah Byrd",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sharon N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Dr. David Gonzalez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brian S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Mathew Long",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cody J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Laurie Collins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bryan V.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Justin Jacobson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kenneth R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Andrew Johnson DDS",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kerri B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Dwayne Heath",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Preston F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Michelle Lowe",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Rhonda Mejia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Devin B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "James Rose",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alec B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Brenda Armstrong",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Johnny D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Brent Adkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "David G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Ashley Robinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alejandro T.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Gary Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy N.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Renee Knight",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian F.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Alexander Pacheco",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Shane Hughes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffery E.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Amanda Bowen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Margaret J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Shannon Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Felicia Wiggins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Travis M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Adam Thompson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy E.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Michael Becker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Glenn R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Patrick Bishop",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffrey P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Joanne Robinson",
        "service_type": "Garage Door Installation",
        "tech_name": "Ronald H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Dana Young",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Erin D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Nicholas Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Donna Gonzalez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Pamela Q.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Vanessa Hernandez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christine M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Tracy Bernard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Audrey W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Heather Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gregory J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Jonathan Zamora",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Brian Lutz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Diana J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Margaret Cruz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandon W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Dana Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Amanda F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Richard West",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Christine Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ronald B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Stephanie Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Linda M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Robert Bryant",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Victoria C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Danielle Mcbride",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jocelyn E.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Samantha Flynn",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cynthia W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Brian Cordova",
        "service_type": "Garage Door Repair",
        "tech_name": "Alexis O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Lauren Crawford",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dennis R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Jennifer Meyer",
        "service_type": "Garage Door Services",
        "tech_name": "Laura F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Kimberly Monroe",
        "service_type": "Garage Door Repair",
        "tech_name": "Kristina J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Casey Jackson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jordan Drake",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jacob L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Susan Wilkinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tracey J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Lindsay Stewart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cynthia M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Timothy Oliver",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela N.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Brian Mora",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Bailey Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Robert Owens",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffery C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Mrs. Desiree Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Marvin W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Alexis Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Megan S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Tyler Rojas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lindsey M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Shannon Cline",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kathleen B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Tanya Matthews",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Blake R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Michael Campos",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Zachary Hernandez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Victor G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Greg Bradley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Timothy J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Anderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "David Lee",
        "service_type": "Garage Door Repair",
        "tech_name": "David J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Peter Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Paul K.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Natasha Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jonathan W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Laura Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Danny Martin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samuel M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Tracy Sparks",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tiffany C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Jason Sanchez",
        "service_type": "Garage Door Repair",
        "tech_name": "Adam B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Matthew Nunez MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kimberly K.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Katrina Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Rachel T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Amy Huff",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joseph D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Janice White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Melissa Medina",
        "service_type": "Garage Door Opener",
        "tech_name": "Sherry H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Sean Glover",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Henry G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "William Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Kim Hawkins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mason L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Gerald Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Beth H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "James Hawkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mandy E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Veronica Watkins",
        "service_type": "Garage Door Installation",
        "tech_name": "James B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Tracy Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Tiffany Norman",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Dustin Dudley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Edward K.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Shelby Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicholas N.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Beth Lucas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Edward R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Gregory Murphy",
        "service_type": "Garage Door Installation",
        "tech_name": "Lindsey S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Cynthia Singleton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Taylor W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Jamie Stevens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Carla Campos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Mr. James Combs",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Dr. Cheryl Torres",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Janice F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Dennis Thompson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marco L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Michael Stevens",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Earl Velasquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Devin O.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
