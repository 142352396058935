import React, { useState } from 'react'
import closeMenu from '../../Images/closeMenu.png'
import next from '../../Images/next.png'
import './Menu.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'

export default function Menu({ navigateTo, setShowMenu, WebSitePages }) {

    const [showMakers, setshowMakers] = useState(false);
    return (
        <div className="Menu">
            <div className="MenuBoxShadow" onClick={() => setShowMenu(false)}></div>
            <div className="MenuList">
                <div className="MenuListTop">
                    <div className="MenuMain">
                        <div className="MenuMainBox">
                            <a href='/'>HOME PAGE</a>
                        </div>
                        <div className="MenuMainBox" onClick={() => {
                            setshowMakers(true)
                        }}>
                            <h1 >SERVICES</h1>
                            <img src={next} alt="Garage Door In Santa Clarita, CA | Garage Door Repair In Santa Clarita, CA | Garage Door Services In Santa Clarita, CA | Garage Door Inspection In Santa Clarita, CA" />
                        </div>
                        <div className="MenuMainBox">
                            <a href='/book'>SCHEDULE ONLINE</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href={`/reviews/${LocationData.find(a => a.City == ("Santa Clarita").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>REVIEWS</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/contact'>CONTACT US</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/locations'>OUR LOCATIONS</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/blog'>BLOG</a>
                        </div>
                    </div>
                    <div className="MenuFooter">
                        <h5>Copyright © 2024 Santa Clarita Garage Door. All rights reserved.</h5>
                    </div>

                    {/* showCategories */}
                    {showMakers ?
                        <div className="MenuMakers">
                            <div className="MenuMakersHeader">
                                <img src={next} alt="Garage Door In Santa Clarita, CA | Garage Door Repair In Santa Clarita, CA | Garage Door Services In Santa Clarita, CA | Garage Door Inspection In Santa Clarita, CA" onClick={() => {
                                    setshowMakers(false)
                                }} />
                                <h1 onClick={() => {
                                    // navigateTo("")
                                }}>Services</h1>
                            </div>
                            <div className="MenuMakersList">
                                <div className="MenuMakersListBox">
                                    {AreaData.map((A, index) =>
                                        <a href={A.Path} className="Header1BoxMenuBox">
                                            {A.TitleShortVery}
                                        </a>
                                    )}
                                </div>

                            </div>

                        </div>
                        : null}
                </div>
                <div className="MenuListFooter">
                    <a href="tel:661-485-4428">CALL NOW 661-485-4428</a>
                    <a href="/book">SCHEDULE ONLINE</a>
                    <p>
                        Monday - Sunday
                        <br />
                        8:00 a.m. - 8:00 p.m. EST
                    </p>
                </div>

            </div>
        </div>
    )
}
